import React from 'react';
import { ImSection } from 'react-icons/im';
import ItemTitle from '../../components/ItemTitle';
import ScoreMatching from '../../components/ScoreMatching';
import { getRecordTitle, ISolrRegistryDoc } from '../../init';

interface ITopRecordProps extends React.AllHTMLAttributes<HTMLDivElement> {
	record: ISolrRegistryDoc;
	matching: number;
}
const TopRecord = ({ record, matching }: ITopRecordProps) => {
	const { data, registryId, id } = record;
	return (
		<div className="d-flex justify-content-between align-items-start">
			<ItemTitle
				targetId={`record_${id}`}
				className="text-dark text-truncate"
				title={getRecordTitle(data, registryId.toString(), true) as string}
				scrollDelta={-56 - 59}
			>
				<ImSection />
				{getRecordTitle(data, registryId.toString())}
			</ItemTitle>
			<ScoreMatching matching={matching} />
		</div>
	);
};

export default TopRecord;
