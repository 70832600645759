import React from 'react';
import { IDefiniteRegistryDoc, TNumberOrNull } from '../../init';
import RecordList from './RecordList';
import RegistryHeader from './RegistryHeader';

interface IRegistryItemProps extends React.AllHTMLAttributes<HTMLDivElement> {
	selectedCount: number;
	totalCount: number;
	registryId: string;
	title: string;
	subtitle?: string;
	records: IDefiniteRegistryDoc[];
	maxScore: number;
	searchRegExp?: RegExp;
	selectedRegistryId: TNumberOrNull;
}
const RegistryItem = ({
	records,
	registryId,
	selectedCount,
	totalCount,
	title,
	subtitle,
	maxScore,
	searchRegExp,
	selectedRegistryId,
}: IRegistryItemProps) => {
	return (
		<section id={`registry_${registryId}`}>
			<RegistryHeader
				selectedCount={selectedCount}
				totalCount={totalCount}
				title={title}
				subtitle={subtitle}
				registryId={registryId}
				selectedRegistryId={selectedRegistryId}
			/>
			<RecordList records={records} registryId={registryId} maxScore={maxScore} searchRegExp={searchRegExp} />
		</section>
	);
};

export default RegistryItem;
