import { Col } from 'react-bootstrap';
import { ISolrSearchResult, TDictionary } from '../../init';
import RegistryItem from '../registry/RegistryItem';
import { generateSearchRegExp } from './searchSlice';

interface ISearchMainProps extends React.AllHTMLAttributes<HTMLDivElement> {
	searchResult: ISolrSearchResult;
	dicRegistry: TDictionary;
}
const SearchMain = ({ dicRegistry, searchResult }: ISearchMainProps) => {
	const {
		params: { term },
		facets,
		recordsByRegisters,
		header: { maxScore },
		params: { registryId },
	} = searchResult;

	const searchRegExp = generateSearchRegExp(term);
	return (
		<Col xs={12} xxl={9}>
			{facets.map(({ id, selected, total }) => (
				<RegistryItem
					key={id}
					selectedCount={selected || 0}
					totalCount={total}
					registryId={id}
					title={dicRegistry[id].title}
					subtitle={dicRegistry[id].subtitle}
					records={recordsByRegisters[id]}
					maxScore={maxScore}
					searchRegExp={searchRegExp}
					selectedRegistryId={registryId}
				/>
			))}
		</Col>
	);
};

export default SearchMain;
