import React from 'react';
import { Alert } from 'react-bootstrap';
import { BsInfoCircle } from 'react-icons/bs';
import styled from 'styled-components';
import { IDefiniteRegistryDoc } from '../../init';
import RecordItem from './RecordItem';

interface IRecordListProps extends React.AllHTMLAttributes<HTMLDivElement> {
	records: IDefiniteRegistryDoc[];
	registryId: string;
	maxScore: number;
	searchRegExp?: RegExp;
}
const RecordList = ({ records, registryId, maxScore, searchRegExp }: IRecordListProps) => {
	if (!records)
		return (
			<Alert variant="info">
				<BsInfoCircle className="me-1" />
				Записи, знайдені в результати пошуку по даному реєстру, мають релевантність нижче, ніж решта, тому вони не
				показані. Якщо Вам потрібні результати пошуку саме з цього реєстру, використайте фільтр.
			</Alert>
		);
	return (
		<RecordListSection className="registry-items-list">
			{records.map((record, index) => (
				<RecordItem
					key={record.id}
					record={record}
					index={index + 1}
					registryId={registryId}
					matching={maxScore !== 0 ? record.score / maxScore : 1}
					searchRegExp={searchRegExp}
				/>
			))}
		</RecordListSection>
	);
};

export default RecordList;

const RecordListSection = styled.section`
	& .table {
		--bs-table-bg: rgba(255, 255, 255, 0.8);
		// --bs-table-striped-bg: rgba(0, 0, 0, 0.1);
	}
`;
