import { useEffect } from 'react';
import styled from 'styled-components';
import AppImg from '../img/stack_of_books.webp';
import LoginButton from './LoginButton';

const SplashScreen = () => {
	useEffect(() => {
		const body = document.querySelector('body');
		if (!body) return;

		body.classList.add('not-auth');

		return () => body.classList.remove('not-auth');
	}, []);

	return (
		<SplashContainer className="d-flex flex-row gap-2 align-items-center m-auto">
			<RegistryImage src={AppImg} />
			<LoginButton large className="z-index-1" />
			{/* <SplashThumbnail /> */}
		</SplashContainer>
	);
};

export default SplashScreen;

const SplashContainer = styled.div`
	padding-bottom: 90px;
`;
const RegistryImage = styled.img`
	height: 50vmin;
	width: auto;
	filter: drop-shadow(0 0 5px rgba(255, 255, 255, 0.5));
`;
