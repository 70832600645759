import { BsSearch } from 'react-icons/bs';
import styled from 'styled-components';

// interface ISearchWaiterProps extends React.AllHTMLAttributes<HTMLDivElement> {}
const SearchWaiter = () => {
	return (
		<LoadWaiter className="h5 bg-warning p-4 rounded shadow">
			{/* <PageTitle title={`⏳ «${term}»`} /> */}
			<BsSearch className="animation-bounce me-2" />
			Завантаження записів реєстру. Зачекайте...
		</LoadWaiter>
	);
};

export default SearchWaiter;

const LoadWaiter = styled.div`
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 1;
`;
