import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import appReducer from './features/app/appSlice';
import searchReducer from './features/search/searchSlice';

const store = configureStore({
	reducer: {
		app: appReducer,
		search: searchReducer,
	},
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppDispatch: () => AppDispatch = useDispatch;

export const selectAppStore = (state: RootState) => state.app;
export const selectSearchStore = (state: RootState) => state.search;

export default store;
