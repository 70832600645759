import { GetTokenWithPopupOptions } from '@auth0/auth0-react';
import { BsCoin } from 'react-icons/bs';

export type TStringWithUndefined = string | undefined;
export type TNumberWithUndefined = number | undefined;
export type TNumberOrNull = number | null;

export interface IErrorResponse {
	error: string;
}

export interface IRegistryInfo {
	registryId: number;
	title: string;
	subtitle?: string;
}

export type TDictionary = { [key: string]: IRegistryInfo };
export interface IDictionary {
	registry: TDictionary;
}

export interface ISearchParams {
	term: string;
	registryId: TNumberOrNull;
}

export interface IFacetItem {
	id: string;
	total: number;
	selected?: number;
}
export type TFacets = IFacetItem[];
export type TRegistryObject = { [key: string]: string | TRegistryObject };
export interface IDefiniteRegistryDoc {
	id: string;
	data: TRegistryObject;
	score: number;
}
export interface ISolrRegistryDoc extends IDefiniteRegistryDoc {
	registryId: number;
}
export interface ISolrSearchResult {
	params: ISearchParams;
	header: {
		QTime: number;
		numFound: number;
		maxScore: number;
	};
	recordsByRegisters: {
		[key: string]: IDefiniteRegistryDoc[];
	};
	facets: TFacets;
	topRecords: ISolrRegistryDoc[];
}

export const DEFAULTS = {
	pageTitle: 'Реєстри',
	// searchParams: {
	// 	term: '',
	// 	hitDate: dayjs().format('YYYY-MM-DD'),
	// } as ISearchParams,
	availableScopes: 'read:stater',
	accessPermissionName: 'read:stater',
	locale: 'uk-UA',
	lastUpdate: 'січень 2022',
};

export const AUTH0_OPTIONS: GetTokenWithPopupOptions = {
	audience:
		process.env.NODE_ENV === 'production'
			? (process.env.REACT_APP_PROD_AUTH0_AUDIENCE as string)
			: (process.env.REACT_APP_DEV_AUTH0_AUDIENCE as string),
	scope: DEFAULTS.availableScopes,
};

/**
 * It checks if a permission exists in a list of permissions
 * @param {string} permissionForCheck - The permission you want to check for.
 * @param {string[]} permissions - The permissions that the user has.
 */
export const permissionExists = (permissionForCheck: string, permissions: string[] = []) =>
	permissions.includes(permissionForCheck);

export const humanizeNumber = (number: number, numbersOnly = true) =>
	number !== 0 ? number.toLocaleString(DEFAULTS.locale) : numbersOnly ? number : '—';

export const getRegistryFromString = (strRegistry?: string) => (strRegistry ? parseInt(strRegistry, 10) || null : null);

export const getRegistryFullTitle = (title: string, subtitle?: string, oneColor = false) => (
	<>
		{title} {subtitle && (oneColor ? subtitle : <span className="text-muted">{subtitle}</span>)}
	</>
);

export const getRecordTitle = (item: TRegistryObject, registryId: string, textOnly = false) => {
	let title =
		registryId !== '1003' ? (
			(((item.NAME as unknown) ||
				(item.FIO as unknown) ||
				(item.NAMES_AGENC as unknown) ||
				(item['Назва укр.'] as unknown)) as string)
		) : textOnly ? (
			`${item['Боржник'] as unknown} — ${item['Стягувач'] as unknown}`
		) : (
			<>
				{item['Боржник'] as unknown}
				<BsCoin className="mx-1 text-success" />
				{item['Стягувач'] as unknown}
			</>
		);
	if (title === undefined && typeof item.NAMES_LANG === 'object') {
		const names = item.NAMES_LANG.NAME_LANG as string;
		title = Array.isArray(names) ? names.join(' :: ') : names;
	}
	return title;
};
