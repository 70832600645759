import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import { Alert, Col, Container, Row } from 'react-bootstrap';
import { BsInfoCircle } from 'react-icons/bs';
import { showSystemError } from '../alerts';
import { clearSearchParams } from '../features/search/searchSlice';
import FacetList from '../features/stat/FacetList';
import SearchImage from '../img/undraw_file_searching_re_3evy.svg';
import { AUTH0_OPTIONS, DEFAULTS, TFacets } from '../init';
import { useAppDispatch } from '../store';
import DotSpinner from './DotSpinner';
import Caption from './caption';

const StartPage = () => {
	const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0();
	const [facets, setFacets] = useState<TFacets>();
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(clearSearchParams());
	}, []); // eslint-disable-line

	useEffect(() => {
		const getFacets = async () => {
			let accessToken: string;
			try {
				accessToken = await getAccessTokenSilently(AUTH0_OPTIONS);
			} catch (error) {
				accessToken = await getAccessTokenWithPopup(AUTH0_OPTIONS);
			}
			try {
				const response = await fetch(`api/stat`, {
					headers: {
						Authorization: `Bearer ${accessToken}`,
						// 'c-user-email': user?.email || 'n/a',
					},
				});
				if (!response.ok) {
					throw new Error(`${response.status} ${response.statusText}: ${await response.text()}`);
				}
				const result = await response.json();
				setFacets(result);
			} catch (error) {
				showSystemError(error as Error);
			}
		};
		getFacets();
	}, []); // eslint-disable-line

	return (
		<Container fluid="xl">
			<Row>
				<Col xs={12} xl={{ span: 10, offset: 1 }}>
					{facets ? (
						<FacetList facets={facets} title="Перелік наявних реєстрів" />
					) : (
						<DotSpinner>Завантаження статистичної інформації про реєстри</DotSpinner>
					)}
				</Col>
				<Col xs={{ span: 8, offset: 2 }}>
					<Caption
						// title="Скористайтесь формою та виконайте пошук"
						className="flex-grow-1 justify-content-center text-center"
						level={3}
						imgSrc={SearchImage}
						alt="Зображення пошуку"
						// imageFirst
						// colorClassName="text-primary"
						imgClassName="w-25"
					/>
				</Col>
				<Col xs={12} xl={{ span: 10, offset: 1 }}>
					<Alert variant="warning" className="mt-3">
						<BsInfoCircle className="mt-n1 me-1" />
						Зверніть увагу на те, що наразі відсутня можливість отримати оновлення державних реєстрів. Тож представлені
						відомості відповідають записам на <strong className="text-nowrap">{DEFAULTS.lastUpdate}</strong>.
					</Alert>
				</Col>
			</Row>
		</Container>
	);
};

export default StartPage;
