import { Auth0Provider } from '@auth0/auth0-react';
import 'bootstrap/scss/bootstrap.scss';
// import dayjs from 'dayjs';
// import 'dayjs/locale/uk';
// import localizedFormat from 'dayjs/plugin/localizedFormat';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import ErrorBoundary from './components/errorBoundary';
import App from './features/app/App';
import { AUTH0_OPTIONS } from './init';
import store from './store';
import './style.scss';

// dayjs.locale('uk');
// dayjs.extend(localizedFormat);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
// const { origin, hash } = window.location;
root.render(
	<React.StrictMode>
		<ErrorBoundary>
			<Provider store={store}>
				<Auth0Provider
					{...AUTH0_OPTIONS}
					domain={
						process.env.NODE_ENV === 'production'
							? (process.env.REACT_APP_PROD_AUTH0_DOMAIN as string)
							: (process.env.REACT_APP_DEV_AUTH0_DOMAIN as string)
					}
					clientId={
						process.env.NODE_ENV === 'production'
							? (process.env.REACT_APP_PROD_AUTH0_CLIENT_ID as string)
							: (process.env.REACT_APP_DEV_AUTH0_CLIENT_ID as string)
					}
					// redirectUri={`${window.location.href}`}
					redirectUri={window.location.href}
					useRefreshTokens={true}
				>
					<App />
				</Auth0Provider>
			</Provider>
		</ErrorBoundary>
	</React.StrictMode>
);
