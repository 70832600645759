import { FaHome } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Image404 from '../img/undraw_page_not_found_re_e9o6.svg';
import Caption from './caption';
import TextIcon from './textIcon';

// interface IPageNotFoundProps extends React.AllHTMLAttributes<HTMLDivElement> {}
const PageNotFound = () => {
	return (
		<div className="flex-grow-1 d-flex bottom-compensator">
			<Caption title="Відсутня сторінка з такою адресою" imgSrc={Image404} level={3} className="m-auto">
				<Link className="h3 d-block mt-4" to="/">
					<TextIcon Icon={FaHome}>На головну сторінку</TextIcon>
				</Link>
			</Caption>
		</div>
	);
};

export default PageNotFound;
