import classNames from 'classnames';
import { IconType } from 'react-icons';
import { BsJournalText } from 'react-icons/bs';
import CountBadge from '../../components/CountBadge';
import ItemTitle from '../../components/ItemTitle';
import { getRegistryFullTitle } from '../../init';
import RegistryLink from '../search/RegistryLink';

interface IFacetItemProps {
	title: string;
	subtitle?: string;
	totalCount: number;
	selectedCount?: number;
	Icon?: IconType;
	targetId?: string;
	registryId?: string;
	truncateTitle?: boolean;
}
const FacetItem = ({
	title,
	subtitle,
	totalCount,
	selectedCount,
	Icon = BsJournalText,
	targetId,
	registryId,
	truncateTitle = false,
}: IFacetItemProps) => {
	return (
		<div className="d-flex align-items-start gap-1 lh-base">
			{registryId ? <RegistryLink registryId={registryId} /> : <Icon className="flex-shrink-0 mt-1" />}
			<ItemTitle
				targetId={targetId}
				className={classNames('text-dark me-auto', truncateTitle && 'text-truncate')}
				title={`${title}${subtitle ? `${subtitle}` : ''}`}
			>
				{getRegistryFullTitle(title, subtitle)}
			</ItemTitle>
			{/* {registryId && <RegistryLink registryId={registryId} />} */}
			<CountBadge selectedCount={selectedCount} totalCount={totalCount} />
		</div>
	);
};

export default FacetItem;
