import { Container, Nav, Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import SearchForm from '../features/search/searchForm';
import AppIcon from '../img/app.webp';
import { DEFAULTS } from '../init';
import LoginButton from './LoginButton';
import LogoutButton from './LogoutButton';

interface INavHeaderProps extends React.AllHTMLAttributes<HTMLDivElement> {
	isAuthenticated: boolean;
	isLoading: boolean;
	hasAccess: boolean;
}
const NavHeader = ({ isAuthenticated, isLoading, hasAccess }: INavHeaderProps) => {
	return (
		<Navbar bg="primary" variant="dark" expand="md" fixed="top" className="d-print-none">
			<Container fluid>
				<Link to="/" className="navbar-brand">
					<BrandImage src={AppIcon} className="d-inline-block" />
					{DEFAULTS.pageTitle}
				</Link>
				<Navbar.Toggle area-controls="navbarSupportedContent" />
				{hasAccess && (
					<Navbar.Collapse id="navbarSupportedContent" className="gap-3">
						<SearchForm />
					</Navbar.Collapse>
				)}
				<Nav className="ms-auto">{isLoading || isAuthenticated ? <LogoutButton /> : <LoginButton />}</Nav>
			</Container>
		</Navbar>
	);
};

export default NavHeader;

const BrandImage = styled.img`
	width: 40px;
	height: 40px;
	margin: -12px 0.25rem -8px 0;
	filter: drop-shadow(0 0 1px white);
`;
