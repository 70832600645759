import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { showSystemError } from '../../alerts';
import { DEFAULTS, IDictionary, permissionExists } from '../../init';
import { AppDispatch } from '../../store';

export interface IAppSlice {
	userPermissions: string[];
	hasAccess: boolean;
	pageIsVisible: boolean;
	dictionary: IDictionary;
}
const initialState: IAppSlice = {
	userPermissions: [],
	hasAccess: false,
	pageIsVisible: true,
	dictionary: { registry: {} },
};
export const appSlice = createSlice({
	name: 'app',
	initialState,
	reducers: {
		changePermissions: (state, action: PayloadAction<string[] | undefined>) => {
			state.userPermissions = action.payload ? [...action.payload] : [];
			state.hasAccess = permissionExists(DEFAULTS.accessPermissionName, action.payload);
		},
		changePageIsVisible: (state, action: PayloadAction<boolean>) => {
			state.pageIsVisible = action.payload;
		},
		changeDictionary: (state, action: PayloadAction<IDictionary>) => {
			state.dictionary = action.payload;
		},
	},
});

export const { changePageIsVisible, changePermissions } = appSlice.actions;
export const { changeDictionary } = appSlice.actions;

export default appSlice.reducer;

export const loadDictionary = (accessToken: string, userEmail: string) => async (dispatch: AppDispatch) => {
	if (!accessToken) return;

	try {
		const response = await fetch(`api/dictionary`, {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				// 'c-user-email': userEmail,
			},
		});
		if (!response.ok) {
			throw new Error(`${response.status} ${response.statusText}: ${await response.text()}`);
		}
		const result: IDictionary = await response.json();
		dispatch(changeDictionary(result));
	} catch (error) {
		showSystemError(error as Error);
	}
};
