import classNames from 'classnames';
import React from 'react';
import { Badge } from 'react-bootstrap';
import { humanizeNumber } from '../init';

interface ICountBadgeProps extends React.AllHTMLAttributes<HTMLDivElement> {
	selectedCount?: number;
	totalCount: number;
}
const CountBadge = ({ selectedCount, totalCount, className }: ICountBadgeProps) => {
	return (
		<Badge
			bg="secondary"
			className={classNames('flex-shrink-0', className)}
			title={
				selectedCount !== undefined
					? `Показано ${selectedCount} записів з ${totalCount} знайдених`
					: 'Кількість записів'
			}
		>
			{selectedCount !== undefined && <>{humanizeNumber(selectedCount)} з </>}
			{humanizeNumber(totalCount)}
		</Badge>
	);
};

export default CountBadge;
