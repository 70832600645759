import React from 'react';
import { Badge } from 'react-bootstrap';
import SectionTitle from '../../components/SectionTitle';

interface IFacetContainerProps extends React.AllHTMLAttributes<HTMLDivElement> {
	totalCaption?: string;
	totalValue: string;
}
const FacetContainer = ({ totalCaption = 'Разом', className, children, title, totalValue }: IFacetContainerProps) => {
	return (
		<section className={className}>
			{title && (
				<SectionTitle className="text-center mb-0" level={3}>
					{title}
				</SectionTitle>
			)}
			{children}
			<div className="d-flex justify-content-between align-items-start border-top border-dark pt-1 mt-2 h5">
				<div>{totalCaption}:</div>
				<Badge bg="secondary">{totalValue}</Badge>
			</div>
		</section>
	);
};

export default FacetContainer;
