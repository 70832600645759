import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import { Button, ButtonProps } from 'react-bootstrap';
import { FaSignInAlt } from 'react-icons/fa';

interface ILoginButtonProps extends ButtonProps {
	large?: boolean;
}
const LoginButton = ({ large = false, ...rest }: ILoginButtonProps) => {
	const { loginWithRedirect } = useAuth0();

	const onLoginClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
		await loginWithRedirect();
	};

	return (
		<Button {...rest} variant="warning" size={large ? 'lg' : undefined} onClick={onLoginClick}>
			<FaSignInAlt className="me-1" />
			Вхід
		</Button>
	);
};

export default LoginButton;
