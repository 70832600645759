import { useEffect } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { getRegistryFromString } from '../../init';
import { useAppDispatch } from '../../store';
import { changeSearchParams } from '../search/searchSlice';

const SearchParamsFromPath = ({ children }: React.HTMLAttributes<HTMLElement>) => {
	const dispatch = useAppDispatch();
	const { term, registryId } = useParams();

	useEffect(() => {
		dispatch(changeSearchParams({ term: (term || '').trim(), registryId: getRegistryFromString(registryId) }));
	}, [term, registryId]); // eslint-disable-line

	return (term || '').trim() ? <>{children}</> : <Navigate to="/" replace />;
};

export default SearchParamsFromPath;
