import React, { useEffect, useState } from 'react';
import { Alert, Col } from 'react-bootstrap';
import { BsExclamationTriangle, BsInfoCircle } from 'react-icons/bs';
import { ISolrSearchResult, TNumberOrNull } from '../../init';
import { TopStickyDiv } from '../../styledComponents';
import FacetList from '../stat/FacetList';
import TopRecordsList from '../stat/TopRecordsList';

interface ISearchAsideProps extends React.AllHTMLAttributes<HTMLDivElement> {
	selectedRegistryId: TNumberOrNull;
	searchResult: ISolrSearchResult;
}
const SearchAside = ({ selectedRegistryId, searchResult }: ISearchAsideProps) => {
	const [selectedCount, setSelectedCount] = useState<number>();

	const {
		header: { numFound, maxScore },
		facets,
		recordsByRegisters,
		topRecords,
	} = searchResult;

	useEffect(() => {
		if (!recordsByRegisters) return;
		setSelectedCount([...Object.values(recordsByRegisters)].flat().length);
	}, [recordsByRegisters]);

	return (
		<Col xs={12} xxl={{ span: 3, order: 1 }} className="d-print-none">
			<TopStickyDiv>
				{/* <div className="">
				Знайдено записів: <span className="text-info fw-bolder">{numFound}</span> ({QTime} мс)
			</div> */}
				{selectedRegistryId && (
					<Alert variant="warning" className="text-sm lh-sm">
						<BsExclamationTriangle className="me-1" />
						Увага! Наразі пошук обмежено лише одним реєстром.
					</Alert>
				)}
				<FacetList
					facets={facets}
					title="Зміст"
					totalCaption="Знайдено записів"
					targetTemplate="registry_"
					selectedRegistryId={selectedRegistryId}
				/>
				<TopRecordsList records={topRecords} maxScore={maxScore} />
				{selectedCount !== undefined && selectedCount < numFound && (
					<Alert variant="info" className="text-sm lh-sm mt-2">
						<BsInfoCircle className="me-1" />
						Результатів пошуку надто багато, тому вони показані не всі. Спробуйте уточнити запит за допомогою додавання
						ключових слів, або, якщо пошуковою строкою є назва, візьміть її у лапки.
					</Alert>
				)}
			</TopStickyDiv>
		</Col>
	);
};

export default SearchAside;
