import Container from 'react-bootstrap/Container';
import { BsEnvelope } from 'react-icons/bs';
import styled from 'styled-components';
import imgBrand from '../img/brand.webp';
import { DEFAULTS } from '../init';

interface INavFooterProps extends React.AllHTMLAttributes<HTMLDivElement> {
	hasAccess: boolean;
}
const NavFooter = ({ hasAccess }: INavFooterProps) => {
	return (
		<nav className="navbar navbar-light bg-light mt-auto lh-sm d-print-none">
			<Container fluid>
				<div className="d-flex align-items-center w-100">
					<a
						href={`https://${process.env.REACT_APP_DOMAIN_NAME}`}
						className="d-flex align-items-center text-decoration-none"
					>
						<BrandImage alt="Brand" src={imgBrand} className="me-2" />
						<span className="navbar-text">
							Фундація стратегічного моніторингу
							<br />
							загальних процесів
						</span>
					</a>
					{hasAccess && (
						<>
							<span className="navbar-text ms-4">
								Зворотній зв'язок:
								<br />
								<BsEnvelope className="me-1" />
								<span className="semi-bold">sashamolodec@gmail.com</span>
							</span>
							<LastUpdate className="navbar-text ms-auto">
								Оновлено:
								<br />
								{DEFAULTS.lastUpdate}
							</LastUpdate>
						</>
					)}
				</div>
			</Container>
		</nav>
	);
};

const BrandImage = styled.img`
	width: 50px;
	height: 50px;
	margin-top: -4px;
`;

const LastUpdate = styled.span`
	margin-right: 36px;
`;

export default NavFooter;
