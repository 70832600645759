import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { Alert, Container, Row } from 'react-bootstrap';
import { BsJournalText } from 'react-icons/bs';
import { connect, ConnectedProps } from 'react-redux';
import Caption from '../../components/caption';
import PageTitle from '../../components/PageTitle';
import SearchWaiter from '../../components/SearchWaiter';
import NoResultsImage from '../../img/undraw_fall_thyk.svg';
import { AUTH0_OPTIONS, getRegistryFullTitle } from '../../init';
import { RootState } from '../../store';
import SearchAside from './SearchAside';
import SearchMain from './SearchMain';
import { searchInSolr } from './searchSlice';

interface ISearchResultProps extends PropsFromRedux, React.AllHTMLAttributes<HTMLDivElement> {}
const SearchResult = ({
	term,
	selectedRegistryId,
	searchTs,
	searchInSolr,
	searchResult,
	searching,
	dicRegistry,
}: ISearchResultProps) => {
	const { getAccessTokenSilently, getAccessTokenWithPopup, user } = useAuth0();

	useEffect(() => {
		if (!term) return;

		const search = async () => {
			let accessToken: string;
			try {
				accessToken = await getAccessTokenSilently(AUTH0_OPTIONS);
			} catch (error) {
				accessToken = await getAccessTokenWithPopup(AUTH0_OPTIONS);
			}
			searchInSolr(accessToken, user?.email || 'n/a', { term, registryId: selectedRegistryId });
		};
		search();
	}, [term, searchTs, selectedRegistryId]); // eslint-disable-line

	if (searching) return <SearchWaiter />;

	if (!searchResult || searchResult?.header.numFound === 0)
		return (
			<Caption
				title="За Вашим пошуковим запитом результатів не знайдено"
				className="flex-grow-1 justify-content-center text-center"
				level={3}
				imgSrc={NoResultsImage}
				alt="Зображення відсутності результатів"
				imageFirst
				// colorClassName="text-danger"
				// imgClassName="w-25"
			>
				{selectedRegistryId && dicRegistry[selectedRegistryId] && (
					<Alert variant="warning" className="order-2 text-start">
						Наразі пошук обмежено лише одним реєстром:
						<br />
						<BsJournalText className="me-1 mt-n1" />
						{getRegistryFullTitle(
							dicRegistry[selectedRegistryId].title,
							dicRegistry[selectedRegistryId].subtitle,
							true
						)}
					</Alert>
				)}
			</Caption>
		);

	return (
		<Container fluid>
			<PageTitle title={`(${searchResult.header.numFound}) «${term}»`} />
			<Row>
				<SearchAside selectedRegistryId={selectedRegistryId} searchResult={searchResult} />
				<SearchMain searchResult={searchResult} dicRegistry={dicRegistry} />
			</Row>
			{/* <Outlet /> */}
		</Container>
	);
};

const mapState = (state: RootState) => ({
	term: state.search.term,
	selectedRegistryId: state.search.selectedRegistryId,
	searchTs: state.search.searchTs,
	searchResult: state.search.searchResult,
	searching: state.search.searching,
	dicRegistry: state.app.dictionary.registry,
});

const mapDispatch = {
	searchInSolr,
};

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(SearchResult);
