import React from 'react';
import { IconType } from 'react-icons';
import { BsFilterCircle } from 'react-icons/bs';
import { Link } from 'react-router-dom';

interface IRegistryLinkProps extends React.AllHTMLAttributes<HTMLAnchorElement> {
	registryId: string;
	Icon?: IconType;
}
const RegistryLink = ({ registryId, Icon = BsFilterCircle, ...rest }: IRegistryLinkProps) => {
	return (
		<Link to={registryId} relative="path" {...rest} title="Показати записи лише з даного реєстру">
			<Icon />
		</Link>
	);
};

export default RegistryLink;
