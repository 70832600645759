import styled from 'styled-components';

interface ITopStickyDivProps {
	top?: string;
	zIndex?: number;
	bg?: 'default' | string;
}
export const TopStickyDiv = styled.div<ITopStickyDivProps>`
	position: sticky;
	top: ${(props) => props.top || '56px'};
	z-index: ${(props) => props.zIndex || 1};
	background-color: ${(props) => (props.bg === 'default' ? 'var(--main-bg-color)' : props.bg)};
`;
