import React from 'react';
import { Table } from 'react-bootstrap';
import styled from 'styled-components';
import { TRegistryObject } from '../../init';

interface IRecordContentProps extends React.AllHTMLAttributes<HTMLDivElement> {
	item: string | TRegistryObject;
	tableCanOmit?: boolean;
	searchRegExp: RegExp | undefined;
}
const RecordContent = ({ item, tableCanOmit = true, searchRegExp }: IRecordContentProps) => {
	if (typeof item === 'string' || typeof item === 'number') return highlightString(item.toString(), searchRegExp);

	// В наборі даних є значення, що дорівнюють null, тому тут ми залишаємо лише значущі ключі об'єкта. Крім того, відхиляються ключи, значення яких являють собою пусті об'єкти
	const keys = Object.keys(item).filter(
		(key) => item[key] && !(typeof item[key] === 'object' && Object.keys(item[key]).length === 0)
	);
	if (keys.length === 0) return null;

	// Якщо об'єкт фактично є масивом, то виводимо список
	if (Array.isArray(item))
		return (
			<ul className="mb-0">
				{item.filter(Boolean).map((element, index) => {
					return (
						<li key={index}>
							{typeof element === 'string' ? (
								highlightString(element, searchRegExp)
							) : (
								<RecordContent item={element} searchRegExp={searchRegExp} />
							)}
						</li>
					);
				})}
			</ul>
		);

	// Якщо об'єкт містить лише один ключ, то не виводимо таблицю (якщо дозволено)
	if (tableCanOmit && keys.length === 1) return <RecordContent item={item[keys[0]]} searchRegExp={searchRegExp} />;

	return (
		<Table striped bordered size="sm" className="registry-item">
			<tbody>
				{keys.map((key) => {
					const keyTitle = RECORD_ATTRIBUTES[key] || key;
					const value = item[key];
					return (
						<tr key={key}>
							<RowHeader scope="row" title={key}>
								{keyTitle}
							</RowHeader>
							<td className="text-break">
								{typeof value === 'string' ? (
									highlightString(value, searchRegExp)
								) : (
									<RecordContent item={value} searchRegExp={searchRegExp} />
								)}
							</td>
						</tr>
					);
				})}
			</tbody>
		</Table>
	);
};

export default RecordContent;

const RowHeader = styled.th`
	width: 20%;
	font-weight: 700;
`;

const HighlightedText = styled.div`
	background-color: rgba(0, 255, 0, 0.15);
	margin: -0.25rem;
	padding: 0.25rem;
`;

const highlightString = (text: string, searchRegExp: RegExp | undefined) =>
	searchRegExp?.test(text) ? <HighlightedText>{text}</HighlightedText> : <>{text}</>;

// const highlightString = (text: string, searchRegExp: RegExp | undefined) =>
// 	searchRegExp ? (
// 		<span
// 			dangerouslySetInnerHTML={{
// 				__html: text.replace(searchRegExp, `<span class="matched-word">$1</span>`),
// 			}}
// 		></span>
// 	) : (
// 		<>{text}</>
// 	);

const RECORD_ATTRIBUTES: { [index: string]: string } = {
	REG_NUM: 'Реєстраційний №',
	DATE_REG: 'Дата реєстрації',
	NAME: 'Назва',
	SHORT_NAME: 'Коротка назва',
	EDRPOU: 'ЄДРПОУ',
	ADRESS: 'Адреса',
	PHONE: 'Телефон',
	GOVERNMENT: 'Керівництво',
	LICENSE: 'Ліцензія',
	FOUNDERS: 'Засновники',
	FOUNDER: 'Засновник',
	ZASNOVN: 'Засновник',
	KVED: 'КВЕД',
	SVID: 'КВЕД',
	STATE: 'Стан',
	JUDGE: 'Судді',
	SDATE_REG: 'Дата реєстрації',
	NAMES_AGENC: 'Назва',
	TYPE_AGENC: 'Тип',
	// BOSS: 'Голова',

	RECORD: '№ запису',
	CODE: 'Код',
	PRIMARY: 'Основний',
	BENEFICIARIES: 'Бенефіціари',
	ACTIVITY_KINDS: 'Види економічної діяльності',
	SUPERIOR_MANAGEMENT: 'Вищий орган управління',
	SIGNERS: 'Керівництво',
	AUTHORIZED_CAPITAL: 'Статутний капітал',
	EXECUTIVE_POWER: 'Орган виконавчої влади, до сфери управління якого належить підприємство',
	STATUTE: 'Статут',
	REGISTRATION: 'Дата та № запису про проведення державної реєстрації особи',
	OPF: 'Тип ОПФ',
	BRANCHES: 'Відокремлені підрозділи',
	CURRENT_AUTHORITY: 'Місцезнаходження реєстраційної справи',
	CONTACTS: 'Контактна інформація',
	PREDECESSORS: 'Юридичні особи, правонаступником яких є дана',
	ASSIGNEES: 'Правонаступники',
	TERMINATED_INFO: 'Інформація про державну реєстрацію припинення юр. (фіз.) особи',
	TERMINATION_CANCEL_INFO: 'Інформація про відміну державної реєстрації припинення юр. (фіз.) особи',
	TERMINATION_STARTED_INFO: 'Інформація про початок припинення',
	BANKRUPTCY_READJUSTMENT_INFO: 'Дані про перебування в процесі провадження у справі про банкрутство',
	FOUNDING_DOCUMENT_NUM: '№ установчого документу',
	MANAGING_PAPER: 'Найменування розпорядчого акту',
	AUTHORITY_NAME: 'Назва органу',
	AUTHORITY_CODE: 'Код органу',
	TAX_PAYER_TYPE: 'Відомості про відомчий реєстр',
	VUD_NAME: 'Тип',
	// TAX_PAYER_TYPE: 'Тип платника податків',
	VP_DATES: 'Дата відкриття виконавчого провадження щодо особи (для незавершених виконавчих проваджень)',
	EXCHANGE_DATA:
		'Відомості, отримані в порядку взаємного обміну інформацією з відомчих реєстрів органів статистики, Міндоходів, Пенсійного фонду України',
	SIGNER: 'Підписант ЮО',
	CREATE_DATE: 'Дата створення',

	DISTRIBS_ADRES: 'Адреса розповсюджувача',
	OP_DATE: 'Дата операції',
	REASON: 'Причина',
	SBJ_STATE: 'Стан суб’єкта',
	START_DATE: 'Дата взяття на облік',
	END_DATE: 'Дата зняття з обліку',
	START_NUM: 'Обліковий №',

	//ФОП
	FIO: 'П.І.Б.',
	ADDRESS: 'Адреса',
	STAN: 'Стан',
	// ЗМІ
	REG_DATE: 'Дата реєстрації',
	NAMES_LANG: 'Назва',
	NAME_LANG: 'Назва',
	TYPE_DZMI: 'Тип ЗМІ',
	GOAL_TYPE: 'Цільова аудиторія',
	ADRES: 'Адреса',
	REG_OBJ: 'Реєстрант',
	ESTATE_MANAGER: 'Особа, що призначена управителем майна ФОП',
	// '': '',
};
