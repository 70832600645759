import React from 'react';
import { useSelector } from 'react-redux';
import { DEFAULTS, TFacets, TNumberOrNull } from '../../init';
import { selectAppStore } from '../../store';
import FacetContainer from './FacetContainer';
import FacetItem from './FacetItem';

interface IFacetsProps extends React.AllHTMLAttributes<HTMLDivElement> {
	facets: TFacets;
	totalCaption?: string;
	targetTemplate?: string;
	selectedRegistryId?: TNumberOrNull;
}
const FacetList = ({
	facets,
	className,
	title,
	totalCaption = 'Загальна кількість записів',
	targetTemplate,
	selectedRegistryId = null,
}: IFacetsProps) => {
	const {
		dictionary: { registry: dicRegistry },
	} = useSelector(selectAppStore);
	const totalItemsCount = Object.values(facets).reduce((acc, current) => acc + current.total, 0);
	const selectedItemsCount = Object.values(facets).reduce((acc, current) => acc + (current.selected || 0), 0);

	const truncateTitle = 5 < facets.length;
	return (
		<FacetContainer
			className={className}
			totalCaption={totalCaption}
			totalValue={
				selectedItemsCount !== 0
					? `${selectedItemsCount.toLocaleString(DEFAULTS.locale)} з ${totalItemsCount.toLocaleString(DEFAULTS.locale)}`
					: totalItemsCount.toLocaleString(DEFAULTS.locale)
			}
			title={title}
		>
			{facets.map(({ id: registryId, total, selected }) => (
				<FacetItem
					key={registryId}
					title={dicRegistry[registryId].title}
					totalCount={total}
					selectedCount={selected}
					subtitle={dicRegistry[registryId].subtitle}
					targetId={targetTemplate ? `${targetTemplate}${registryId}` : undefined}
					registryId={!selectedRegistryId && targetTemplate ? registryId : undefined}
					truncateTitle={truncateTitle}
				/>
			))}
		</FacetContainer>
	);
};

export default FacetList;
