import React from 'react';
import SectionTitle from '../../components/SectionTitle';
import { ISolrRegistryDoc } from '../../init';
import TopRecord from './TopRecord';

interface ITopRecordsListProps extends React.AllHTMLAttributes<HTMLDivElement> {
	records: ISolrRegistryDoc[];
	maxScore: number;
}
const TopRecordsList = ({ records, maxScore }: ITopRecordsListProps) => {
	return (
		<section className="mt-2">
			<SectionTitle level={3} className="text-center mb-0">
				Топ записів
			</SectionTitle>
			{records.map((record) => (
				<TopRecord key={record.id} record={record} matching={maxScore !== 0 ? record.score / maxScore : 1} />
			))}
		</section>
	);
};

export default TopRecordsList;
