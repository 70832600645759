/**
 * `scrollWindowToTop` is a function that scrolls the window to the top of the page
 * @param {'auto' | 'smooth'} [behavior=auto] - 'auto' | 'smooth' = 'auto'
 */
export const scrollWindowToTop = (behavior: 'auto' | 'smooth' = 'smooth') =>
	window.scrollTo({
		top: 0,
		behavior,
	});

const scrollToOffset = (top = 0) => window.scrollTo({ top, behavior: 'smooth' });

export const jumpToElement = (elementId?: string, scrollDelta = -56) => {
	if (!elementId) return;
	const element = document.getElementById(elementId);
	if (!element) return;
	scrollToOffset(element.offsetTop + scrollDelta);
};
