import React from 'react';
import { jumpToElement } from '../scrolls';

interface IItemTitleProps extends React.AllHTMLAttributes<HTMLElement> {
	targetId?: string;
	scrollDelta?: number;
}
const ItemTitle = ({ targetId, children, scrollDelta, ...rest }: IItemTitleProps) => {
	const onTitleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
		event.preventDefault();
		event.stopPropagation();
		jumpToElement(targetId, scrollDelta);
	};

	return targetId ? (
		<a href={`#${targetId}`} {...rest} onClick={onTitleClick}>
			{children}
		</a>
	) : (
		<span {...rest}>{children}</span>
	);
};

export default ItemTitle;
