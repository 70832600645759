import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { BsFilterCircle, BsSearch } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import TextIcon from '../../components/textIcon';
import { selectSearchStore, useAppDispatch, useAppSelector } from '../../store';
import { changeSearchTs } from '../search/searchSlice';

// interface ISearchFormProps extends React.AllHTMLAttributes<HTMLDivElement> {}
const SearchForm = () => {
	const dispatch = useAppDispatch();
	const { selectedRegistryId, term } = useAppSelector(selectSearchStore);

	const [newTerm, setNewTerm] = useState(term);
	const refSearch = useRef<HTMLInputElement>(null);
	const navigate = useNavigate();

	const onTermChange = (event: React.ChangeEvent<HTMLInputElement>) => setNewTerm(event.target.value);

	const search = (termForSearch: string, registryIdForSearch: number | null) => {
		if (term === termForSearch && selectedRegistryId === registryIdForSearch) {
			dispatch(changeSearchTs());
		}
		const strRegistry = registryIdForSearch ? `/${registryIdForSearch}` : '';
		navigate(`/search/${encodeURIComponent(termForSearch)}${strRegistry}`);
		refSearch.current?.focus();
		refSearch.current?.select();
	};

	const onSearchFormSubmit = (event: React.FormEvent) => {
		event.preventDefault();
		event.stopPropagation();
		search(newTerm, selectedRegistryId);
	};

	const onSearchFormReset = (event: React.FormEvent) => {
		event.preventDefault();
		event.stopPropagation();
		search(newTerm, null);
	};

	useEffect(() => {
		refSearch.current?.focus();
		setTimeout(() => refSearch.current?.select(), 0);
	}, []);

	useEffect(() => {
		setNewTerm(term);
	}, [term]);

	return (
		<Form className="flex-grow-1" role="search" onSubmit={onSearchFormSubmit} onReset={onSearchFormReset}>
			<InputGroup>
				<Form.Control
					name="term"
					type="text"
					className="form-control"
					placeholder="введіть підстроку для пошуку..."
					maxLength={300}
					autoComplete="on"
					value={newTerm}
					onChange={onTermChange}
					ref={refSearch}
				/>
				<Button type="submit" variant="warning" title="Пошук">
					<TextIcon Icon={BsSearch}>Знайти</TextIcon>
				</Button>
				{selectedRegistryId && (
					<Button type="reset" variant="danger" title="Скинути фільтр реєстру">
						<TextIcon Icon={BsFilterCircle} className="icon-lg" />
					</Button>
				)}
			</InputGroup>
		</Form>
	);
};

export default SearchForm;
