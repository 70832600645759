import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { showSystemError } from '../../alerts';
import { ISearchParams, ISolrSearchResult } from '../../init';
import { AppDispatch } from '../../store';

const DEFAULT_SEARCH_PARAMS: ISearchParams = {
	term: '',
	registryId: null,
};

export interface ISearchSlice {
	searching: boolean;
	selectedRegistryId: number | null;
	term: string;
	searchTs: number; // Використовується для можливості виконання пошуку з тим самим пошуковим рядком
	searchResult?: ISolrSearchResult;
}
const initialState: ISearchSlice = {
	searching: false,
	searchTs: new Date().valueOf(),
	selectedRegistryId: null,
	term: '',
};
export const searchSlice = createSlice({
	name: 'search',
	initialState,
	reducers: {
		changeSearching: (state, action: PayloadAction<boolean>) => {
			state.searching = action.payload;
		},
		changeSearchTs: (state) => {
			state.searchTs = new Date().valueOf();
		},
		// changeSelectedRegistryId: (state, action: PayloadAction<number | null>) => {
		// 	state.selectedRegistryId = action.payload;
		// },
		// changeTerm: (state, action: PayloadAction<string>) => {
		// 	state.term = action.payload;
		// },
		clearSearchParams: (state) => {
			state.term = DEFAULT_SEARCH_PARAMS.term;
			state.selectedRegistryId = DEFAULT_SEARCH_PARAMS.registryId;
		},
		changeSearchParams: (state, action: PayloadAction<ISearchParams>) => {
			const newSearchParams = { ...DEFAULT_SEARCH_PARAMS, ...action.payload };
			state.term = newSearchParams.term;
			state.selectedRegistryId = newSearchParams.registryId;
		},
		changeSearchResult: (state, action: PayloadAction<ISolrSearchResult>) => {
			state.searchResult = action.payload;
		},
	},
});

export const { changeSearchTs, clearSearchParams, changeSearchParams } = searchSlice.actions;
const { changeSearchResult, changeSearching } = searchSlice.actions;

export default searchSlice.reducer;

export const searchInSolr =
	(accessToken: string, userEmail: string, searchParams: ISearchParams) => async (dispatch: AppDispatch) => {
		if (!accessToken) return;

		try {
			dispatch(changeSearching(true));

			try {
				const response = await fetch(`api/search/${encodeURIComponent(JSON.stringify(searchParams))}`, {
					headers: {
						Authorization: `Bearer ${accessToken}`,
						// 'c-user-email': userEmail,
					},
				});
				if (!response.ok) {
					throw new Error(`${response.status} ${response.statusText}: ${await response.text()}`);
				}
				const result: ISolrSearchResult = await response.json();
				dispatch(changeSearchResult(result));
			} catch (error) {
				showSystemError(error as Error);
			}
		} finally {
			dispatch(changeSearching(false));
		}
	};

export const generateSearchRegExp = (searchString: string) => {
	let searchRegExp = undefined;
	let regArray: string[] = [];
	const terms = searchString.trim().split(/[-\s]+/);
	for (const term of terms) {
		const withoutStartPlus = term.replace(/^\++/, '');
		const s =
			3 < withoutStartPlus.length
				? term.replace(/[+|()*.?"]/g, '').replace(/[йуеіаоєяиьюэъї]+$/i, '') + '[^\\s]*'
				: withoutStartPlus;
		regArray.push(s);
	}
	// console.log(regArray);
	try {
		searchRegExp = new RegExp(`(${regArray.join('|')})`, 'gi');
	} catch (error) {
		searchRegExp = undefined;
	}
	return searchRegExp;
};
