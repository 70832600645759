import classNames from 'classnames';
import React from 'react';

interface ICaptionProps extends React.AllHTMLAttributes<HTMLDivElement> {
	level?: number;
	imgSrc?: string;
	imgClassName?: string;
	imageFirst?: boolean;
	colorClassName?: string;
}
const Caption = ({
	imageFirst = false,
	level = 5,
	title,
	className,
	imgSrc,
	imgClassName = 'caption__image',
	children,
	colorClassName = 'text-cool',
	alt,
	...rest
}: ICaptionProps) => {
	return (
		<div className={classNames('d-flex flex-column gap-3 align-items-center', className)} {...rest}>
			{title && <div className={classNames(`h${level} my-0`, colorClassName, imageFirst && 'order-1')}>{title}</div>}
			{imgSrc && <img src={imgSrc} className={imgClassName} alt={alt} />}
			{children}
		</div>
	);
};

export default Caption;
