import React from 'react';

interface IScoreMatchingProps extends React.AllHTMLAttributes<HTMLDivElement> {
	matching: number;
	// boxesCount?: number;
}
const ScoreMatching = ({ matching }: IScoreMatchingProps) => {
	const opacityPercent = Math.ceil((matching * 100) / 25) * 25;
	const strMatching = `${(matching * 100).toFixed(1)} %`;

	return (
		// <span class="badge bg-light text-success border border-success float-end rounded-pill">89.9 %</span>
		<span
			title={`${strMatching} від максимуму відповідності`}
			className={`badge bg-light text-success border border-success rounded-pill opacity-${opacityPercent}`}
		>
			{strMatching}
		</span>
		// <Badge bg="info" title="Відповідність" className={`opacity-${opacityPercent}`}>
		// 	{(matching * 100).toFixed(1)} %
		// </Badge>
	);
	// const greensCount = Math.round(matching / (1 / boxesCount));

	// return (
	// 	<div className="doc-score__container d-flex flex-nowrap">
	// 		{Array(boxesCount)
	// 			.fill(null)
	// 			.map((dummy, index) => (
	// 				<span
	// 					key={index}
	// 					title={`${(matching * 100).toFixed(1)} % від максимуму відповідності`}
	// 					className={classNames(
	// 						'doc-score__item mt-3',
	// 						index < greensCount ? 'doc-score__item_green' : 'doc-score__item_gray'
	// 					)}
	// 				></span>
	// 			))}
	// 	</div>
	// );
};

export default ScoreMatching;
