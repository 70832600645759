import React from 'react';
import { BsJournalText } from 'react-icons/bs';
import styled from 'styled-components';
import CountBadge from '../../components/CountBadge';
import { TNumberOrNull } from '../../init';
import { TopStickyDiv } from '../../styledComponents';

interface IRegistryHeaderProps extends React.AllHTMLAttributes<HTMLDivElement> {
	selectedCount: number;
	totalCount: number;
	registryId: string;
	title: string;
	subtitle?: string;
	selectedRegistryId: TNumberOrNull;
}
const RegistryHeader = ({
	selectedCount,
	totalCount,
	registryId,
	title,
	subtitle,
	selectedRegistryId,
}: IRegistryHeaderProps) => {
	return (
		<RegHeader
			className="h2 py-1 border-bottom border-dark border-2 d-flex gap-1 align-items-start"
			// id={`registry_${registryId}`}
			bg="default"
		>
			<BsJournalText className="flex-shrink-0 mt-1" />
			<span className="me-auto">
				{title}
				{subtitle && <small className="ms-2">{subtitle}</small>}
			</span>
			{/* {!selectedRegistryId && <RegistryLink registryId={registryId} />} */}
			<CountBadge selectedCount={selectedCount} totalCount={totalCount} />
		</RegHeader>
	);
};
export default RegistryHeader;

const RegHeader = styled(TopStickyDiv)`
	/* padding-top: 18px; */
`;
