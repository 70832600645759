import { useAuth0 } from '@auth0/auth0-react';
import jwtDecode from 'jwt-decode';
import { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { createHashRouter, Navigate, RouterProvider } from 'react-router-dom';
import Layout from '../../components/layout';
import PageNotFound from '../../components/PageNotFound';
import SplashScreen from '../../components/SplashScreen';
import StartPage from '../../components/StartPage';
import { AUTH0_OPTIONS } from '../../init';
import { RootState } from '../../store';
import SearchParamsFromPath from '../search/SearchParamsFromPath';
import SearchResult from '../search/searchResult';
import { changePageIsVisible, changePermissions, loadDictionary } from './appSlice';

/**
 * Тут використовується HashRouter, тому що при використанні BrowserRouter
 * виникають проблеми з перенаправленням з сайту аутентифікації (через адресу).
 * Для використання BrowserRouter необхідно якимсь чином зберігати pathname
 * оригінальної адреси і оновлювати його після аутентифікації.
 */
const router = createHashRouter([
	{
		path: '/',
		element: <Layout />,
		errorElement: <PageNotFound />,
		children: [
			{
				path: 'search',
				element: (
					<SearchParamsFromPath>
						<SearchResult />
					</SearchParamsFromPath>
				),
				children: [
					{
						path: ':term',
						element: null,
						children: [
							{
								path: ':registryId',
								element: null,
							},
						],
					},
				],
			},
			{
				index: true,
				element: <StartPage />,
			},
			{
				path: '*',
				element: <Navigate to="/" replace />,
			},
		],
	},
]);

interface IAppProps extends PropsFromRedux, React.AllHTMLAttributes<HTMLDivElement> {}
const App = ({ changePageIsVisible, changePermissions, loadDictionary }: IAppProps) => {
	const { isAuthenticated, isLoading, getAccessTokenSilently, getAccessTokenWithPopup, user } = useAuth0();

	useEffect(() => {
		if (!isAuthenticated) return;

		const getDictionaries = async () => {
			let accessToken: string;
			try {
				accessToken = await getAccessTokenSilently(AUTH0_OPTIONS);
			} catch (error) {
				accessToken = await getAccessTokenWithPopup(AUTH0_OPTIONS);
			}
			loadDictionary(accessToken, user?.email || 'n/a');
		};
		getDictionaries();
	}, [isAuthenticated]); // eslint-disable-line

	useEffect(() => {
		const handleVisibilityChange = () => {
			changePageIsVisible(document.visibilityState === 'visible');
		};
		document.addEventListener('visibilitychange', handleVisibilityChange);
		return () => document.removeEventListener('visibilitychange', handleVisibilityChange);
	}, [changePageIsVisible]);

	useEffect(() => {
		const updatePermissions = async (isAuthenticated: boolean) => {
			const accessToken = isAuthenticated ? await getAccessTokenSilently(AUTH0_OPTIONS) : undefined;
			changePermissions(accessToken ? (jwtDecode(accessToken) as { scope: string }).scope.split(' ') : undefined);
		};

		updatePermissions(isAuthenticated);
	}, [isAuthenticated, getAccessTokenSilently, changePermissions]);

	if (!(isAuthenticated || isLoading)) return <SplashScreen />;

	return <RouterProvider router={router} />;
};

const mapState = (state: RootState) => ({
	// currentDate: state.app.currentDate,
	// searchResponse: state.search.searchResponse,
});

const mapDispatch = { changePageIsVisible, changePermissions, loadDictionary };

const connector = connect(mapState, mapDispatch);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(App);
