import React from 'react';

interface ISectionTitleProps extends React.AllHTMLAttributes<HTMLHeadingElement> {
	level?: number;
}
const SectionTitle = ({ level = 3, children, className }: ISectionTitleProps) => {
	const Header = `h${level}` as any;
	return <Header className={className}>{children}</Header>;
};

export default SectionTitle;
