import React from 'react';
import ScoreMatching from '../../components/ScoreMatching';
import { getRecordTitle, IDefiniteRegistryDoc } from '../../init';
import RecordContent from './RecordContent';

interface IRecordItemProps extends React.AllHTMLAttributes<HTMLDivElement> {
	record: IDefiniteRegistryDoc;
	index?: number;
	registryId: string;
	matching: number;
	searchRegExp: RegExp | undefined;
}
const RecordItem = ({ record, index, registryId, matching, searchRegExp }: IRecordItemProps) => {
	const { id, data: item } = record;

	return (
		<section className="mb-2">
			<div className="d-flex gap-1 justify-content-between align-items-start" id={`record_${id}`}>
				<h3 className="d-flex gap-1">
					{index && <span>{index}.</span>}
					<span>{getRecordTitle(item, registryId)}</span>
				</h3>
				<ScoreMatching matching={matching} />
			</div>
			<RecordContent item={item} searchRegExp={searchRegExp} tableCanOmit={false} />
		</section>
	);
};

export default RecordItem;
