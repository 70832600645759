import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import WaitingImage from '../img/undraw_dev_productivity_re_fylf.svg';
import { DEFAULTS } from '../init';
import Caption from './caption';

const formatOptions: Intl.DateTimeFormatOptions = {
	day: '2-digit',
	month: '2-digit',
	weekday: 'short',
};

// interface IEmptyWorkPlaceProps extends React.AllHTMLAttributes<HTMLDivElement> {}
const EmptyWorkPlace = () => {
	const [time, setTime] = useState(new Date().toLocaleTimeString(DEFAULTS.locale, formatOptions));
	const { isLoading } = useAuth0();

	useEffect(() => {
		// const tm = setInterval(() => setTime(dayjs().format(' dd. DD.MM LTS')));
		const tm = setInterval(() => setTime(new Date().toLocaleTimeString(DEFAULTS.locale, formatOptions)));
		return () => clearInterval(tm);
	}, []);

	if (isLoading) return null;

	return (
		<Caption
			className="flex-grow-1 justify-content-center text-center"
			title="Ми вже працюємо над тим, щоб Ви отримали доступ. Повертайтесь пізніше."
			imgSrc={WaitingImage}
			alt="Coming soon"
			imageFirst
		>
			<CurrentTime className="order-2 text-cool">{time}</CurrentTime>
		</Caption>
	);
};

export default EmptyWorkPlace;

const CurrentTime = styled.div`
	font-size: 10vmin;
	line-height: 10vmin;
	font-weight: 500;
`;
